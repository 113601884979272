import 'lazysizes';
import ScrollMagic from 'scrollmagic';
import Glide from '@glidejs/glide'

var sliders = document.querySelectorAll('.glide');

for (var i = 0; i < sliders.length; i++) {
  var glide = new Glide(sliders[i]);

  glide.mount();
}



// document.getElementById('ccPicker').addEventListener('click', function () {
// 	var tabs = document.getElementsByClassName("tab-row__tab");
// 	var i;
// 	for (i = 0; i < tabs.length; i++) {
// 		tabs[i].classList.remove("tab-row__tab--active");
// 	}
// 	var ccTabs = document.getElementsByClassName("tab-row__tab--cc");
// 	for (i = 0; i < ccTabs.length; i++) {
// 		ccTabs[i].classList.add("tab-row__tab--active");
// 	}
// 	var congContent = document.getElementsByClassName("cong-content");
// 	var i;
// 	for (i = 0; i < congContent.length; i++) {
// 		congContent[i].style.display = 'none';
// 	}
// 	var ccContent = document.getElementsByClassName("cc-content");
// 	for (i = 0; i < ccContent.length; i++) {
// 		ccContent[i].style.display = 'grid';
// 		ccContent[i].style.opacity = 1;
// 	}
// 	document.getElementById('ccPicker').classList.add("tab-row__tab--active");
// });


// document.getElementById('delcoPicker').addEventListener('click', function () {
// 	var tabs = document.getElementsByClassName("tab-row__tab");
// 	var i;
// 	for (i = 0; i < tabs.length; i++) {
// 		tabs[i].classList.remove("tab-row__tab--active");
// 	}
// 	var ccTabs = document.getElementsByClassName("tab-row__tab--delco");
// 	for (i = 0; i < ccTabs.length; i++) {
// 		ccTabs[i].classList.add("tab-row__tab--active");
// 	}
// 	var congContent = document.getElementsByClassName("cong-content");
// 	var i;
// 	for (i = 0; i < congContent.length; i++) {
// 		congContent[i].style.display = 'none';
// 	}
// 	var ccContent = document.getElementsByClassName("delco-content");
// 	for (i = 0; i < ccContent.length; i++) {
// 		ccContent[i].style.display = 'grid';
// 		ccContent[i].style.opacity = 1;
// 	}
// 	document.getElementById('delcoPicker').classList.add("tab-row__tab--active");
// 	// document.getElementById('delcoPicker2').classList.add("tab-row__tab--active");

// });


// document.getElementById('mnykPicker').addEventListener('click', function () {
// 	var tabs = document.getElementsByClassName("tab-row__tab");
// 	var i;
// 	for (i = 0; i < tabs.length; i++) {
// 		tabs[i].classList.remove("tab-row__tab--active");
// 	}
// 	var ccTabs = document.getElementsByClassName("tab-row__tab--mnyk");
// 	for (i = 0; i < ccTabs.length; i++) {
// 		ccTabs[i].classList.add("tab-row__tab--active");
// 	}
// 	var congContent = document.getElementsByClassName("cong-content");
// 	var i;
// 	for (i = 0; i < congContent.length; i++) {
// 		congContent[i].style.display = 'none';
// 	}
// 	var ccContent = document.getElementsByClassName("mnyk-content");
// 	for (i = 0; i < ccContent.length; i++) {
// 		ccContent[i].style.display = 'grid';
// 		ccContent[i].style.opacity = 1;
// 	}
// 	document.getElementById('mnykPicker').classList.add("tab-row__tab--active");
// 	// document.getElementById('mnykPicker2').classList.add("tab-row__tab--active");

// });


(function() {
	let scene;
	// Init ScrollMagic
	let controller = new ScrollMagic.Controller();

	// Run through all sections
	let items = document.querySelectorAll("section");

	items.forEach(function(element) {
		let height = element.clientHeight; //height of current element
        let sectionId = element.id;
		scene = new ScrollMagic.Scene({
			duration: height,
			triggerElement: element,
			triggerHook: 0.6,
			reverse: true
		})
		.on("enter leave", function() {
            console.log(sectionId);
            var x = document.getElementsByClassName("background");
            var i;
            for (i = 0; i < x.length; i++) {
                x[i].style.opacity = 0;
                // x[i].classList.remove("background--active");
            }
            var $targetId = sectionId+"_bg";
            var z = document.getElementById($targetId);
            // z.classList.add("background--active")
            z.style.opacity = 1;
        })
        // .addIndicators({ name: " - begin " }) // Testing
        .addTo(controller);
	});

	// Scene duration on resize
	// Currently resets page to update duration. Hacky?
	window.addEventListener("resize", function() {
		//window.location.reload(false); // Codepen hates this. Just refreshes page.
	});

})();
